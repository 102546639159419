import React, { useEffect } from "react"
import {AtollonProvider} from "../../plugins/gatsby-plugin-atollon";
import layoutQuery from "../hooks/use-layout-query"
import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'
import { ScrollTrigger } from "gsap/ScrollTrigger"

const Layout = (props) => {

  const globalData = layoutQuery()

  useEffect(() => {
    const lines = document.querySelectorAll('.line:not(.header__visual .line)');

    lines.forEach((el) => {
      ScrollTrigger.create({
        trigger: el,
        start: "top bottom",
        onEnter: () => {
          el.classList.add('is-inview');
        },
      });
    });

    return () => {
    };
  }, [props.location?.pathname]);


  return (
    <AtollonProvider {...props} globalData={globalData}>
      <Header pageContext={props.pageContext} optionsPage={globalData.optionsPage} />
      <main>
        {props.children}
      </main>
      <Footer subscribeForm={globalData.subscribeForm} pathname={props.location?.pathname} />
    </AtollonProvider>
  )
}

export default Layout