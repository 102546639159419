import {useStaticQuery, graphql} from "gatsby"

const useServiceQuery = () => {
  const {allDatoCmsService} = useStaticQuery(graphql`
      query Service {
        allDatoCmsService(sort: {position: ASC}) {
          nodes {
            title
            slug
            jsonLd
            permalink
            seoMetaTags {
              tags
            }
            meta {
              publishedAt(formatString: "DD MMMM YYYY")
            }
            image {
              ...InternalAssetProps
            }
          }
        }
      }
`)

  return allDatoCmsService.nodes
}

export default useServiceQuery