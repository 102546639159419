import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    query {
      ...GlobalData
      subscribeForm: datoCmsForm(title: {eq: "Subscribe"}) {
        ...FormProps
      }
      optionsPage: datoCmsSiteOptionPage {
        projectsMenuImage {
          ...InternalAssetProps
        }
        projectMenuLink {
          ... on DatoCmsPage {
            slug
          }
          ... on DatoCmsProject {
            slug
          }
        }
        cultureMenu {
          image {
            ...InternalAssetProps
          }
          link {
            ...LinkWithTitleProps
          }
        }
      }
    }
  `)
}

export default useLayoutQuery