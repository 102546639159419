import React from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(DrawSVGPlugin)
gsap.registerPlugin(MotionPathPlugin)

const HeaderVisual = ({ menuHover, hasOffCanvas }) => {
  const headerRef = React.useRef(null)
  const svgRef1 = React.useRef(null)
  const svgRef2 = React.useRef(null)
  const svgRef3 = React.useRef(null)
  const lines = headerRef.current?.querySelectorAll(".line")

  React.useEffect(() => {
    if (hasOffCanvas) {
      const tl = gsap.timeline({ paused: true })
      lines.forEach(line => line.classList.remove("is-inview"))
      document.querySelector(".line--mobile").classList.remove("is-inview")

      tl.fromTo(".off-canvas__nav", {
        opacity: 0,
      }, {
        opacity: 1,
        duration: 0.3,
        delay: 0.2,
      })

      gsap.fromTo(
        svgRef1.current?.querySelector("path"),
        { drawSVG: "0", stroke: "#ffffff" },
        { drawSVG: "100%", duration: 1, ease: "sine.inOut", stroke: "#333333" },
      )
      gsap.fromTo(
        svgRef2.current?.querySelector("path"),
        { drawSVG: "0", stroke: "#ffffff" },
        { drawSVG: "100%", duration: 1, ease: "sine.inOut", stroke: "#333333" },
      )
      gsap.fromTo(
        svgRef3.current?.querySelector("path"),
        { drawSVG: "0", stroke: "#ffffff" },
        {
          drawSVG: "100%",
          duration: 1,
          ease: "sine.inOut",
          stroke: "#333333",
          onComplete: () => {
            tl.play()
            lines.forEach(line => line.classList.add("is-inview"))
            document.querySelector(".line--mobile").classList.add("is-inview")
          },
        },
      )

    }
  }, [hasOffCanvas])

  return (
    <div className={`header__visual ${menuHover ? "" : "active"}`} ref={headerRef}>
      <div className="line line--horizon line--top center-left"></div>
      <div className="line line--horizon line--bottom center-right"></div>

      <div className="logo">
        <div className="logo--f">
          <div className="logo--inside">
            <div className="line line--horizon center-left"></div>
            <div className="line line--horizon center-right"></div>
            <div className="line line--vertical center-top"></div>
            <svg ref={svgRef1} xmlns="http://www.w3.org/2000/svg" width="292" height="468" viewBox="0 0 292 468"
                 fill="none">
              <path
                d="M109.918 193.685H234.814V295.388H109.918H109.418V295.888V466.77H0.5V0.929565H291.384V102.633H109.839H109.339L109.339 103.133L109.418 193.185L109.418 193.685H109.918Z"
                stroke="#333333" />
            </svg>
          </div>
        </div>
        <div className="logo--s">
          <div className="line line--vertical center-bottom"></div>
          <div className="line line--vertical center-top"></div>
          <div className="logo--inside">
            <svg ref={svgRef2} xmlns="http://www.w3.org/2000/svg" width="373" height="488" viewBox="0 0 373 488"
                 fill="none">
              <path
                d="M130.717 140.478C130.717 121.776 147.845 105.118 182.259 105.118C226.179 105.118 248.885 125.784 265.384 159.102L358.096 106.375C323.054 37.6966 261.22 1 182.259 1C99.1326 1 20.8779 53.0195 20.8779 143.071C20.8779 233.123 90.9615 265.734 162.381 285.143C234.507 304.474 262.006 315.789 262.006 344.47C262.006 362.465 248.963 382.503 196.715 382.503C144.467 382.503 112.882 356.493 95.7541 317.832L1 371.187C27.792 439.866 92.3757 486.542 193.337 486.542C294.298 486.542 371.924 433.187 371.924 343.135C371.924 244.439 290.212 219.765 217.379 198.391C142.503 176.389 130.874 161.695 130.874 140.4"
                stroke="#333333" />
            </svg>
          </div>
        </div>
        <div className="logo--c">
          <div className="line line--vertical center-bottom"></div>
          <div className="logo--inside">
            <svg ref={svgRef3} xmlns="http://www.w3.org/2000/svg" width="464" height="486" viewBox="0 0 464 486"
                 fill="none">
              <path
                d="M250.528 0.578613C340.196 0.578613 418.617 46.647 462.581 115.639L369.168 165.387C343.508 128.371 299.952 104.011 250.528 104.011C171.618 104.011 107.582 166.141 107.582 242.81C107.582 319.479 171.618 381.61 250.528 381.61C300.255 381.61 344.019 356.948 369.588 319.532H370.614L463.29 368.883C419.481 438.425 340.667 485.042 250.528 485.042C112.744 485.042 1.02148 376.577 1.02148 242.81C1.02148 109.044 112.744 0.578613 250.528 0.578613Z"
                stroke="#333333" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderVisual