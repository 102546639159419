import React, { useEffect, useRef } from "react"
import { Link, Asset } from "../../plugins/gatsby-plugin-atollon"
import HeaderVisual from "./structured-text/blocks/header-visual"
import { Arrow, BigLogo, Logo, RightArrow, RightArrowSmall, ArrowLeft } from "./icons"
import parse from "html-react-parser"
import useServiceQuery from "../hooks/use-service-query"
import gsap from "gsap"

const Header = ({ optionsPage }) => {
  const [offCanvas, setOffCanvas] = React.useState(false)
  const [hoveredIndex, setHoveredIndex] = React.useState(null)
  const [scrolled, setScrolled] = React.useState(false)
  const [scrolledLanding, setScrolledLanding] = React.useState(false)
  const [scrolledFooter, setScrolledFooter] = React.useState(false)
  const [sticky, setSticky] = React.useState(false)
  const [offCanvasMenu, setOffCanvasMenu] = React.useState(null)
  const [dropdownMenu, setDropdownMenu] = React.useState(null)
  const [direction, setDirection] = React.useState("down")
  const [subMenu, setSubMenu] = React.useState(false)
  const services = [...useServiceQuery()]
  const serviceCardsRef = useRef([])
  const cultureCardsRef = useRef([])
  const projectCardsRef = useRef([])

  const handleMouseEnter = (index) => {
    setHoveredIndex(index)
  }

  const handleMouseLeave = () => {
    setHoveredIndex(null)
  }

  const openOffCanvas = () => {
    setOffCanvas(!offCanvas)
    const serviceIntroExtension = document.querySelector(".service-intro__extension")
    if (serviceIntroExtension) {
      serviceIntroExtension.classList.toggle("hide")
    }
  }

  const closeMenu = () => {
    setOffCanvasMenu({
      subActive: false,
      menu: [],
      initial: false,
    })
    const serviceIntroExtension = document.querySelector(".service-intro__extension")
    if (serviceIntroExtension) {
      serviceIntroExtension.classList.toggle("hide")
    }
    setDropdownMenu({
      dropdownActive: false,
      menu: [],
      initial: false,
    })
    setOffCanvas(false)
  }

  const dropdownToggle = (key) => {
    setDropdownMenu(key)
    setSubMenu(key)
    handleMouseEnter(key)

    if (!key) {
      handleMouseLeave(null)
    }
  }

  let isScrolling
  let prevScroll = 0

  const onScroll = (e) => {

    let scr, hhScrolled

    const curScroll = window.scrollY || document.scrollTop
    const stickyHeader = document?.querySelector(".sticky-header")
    scr = curScroll > 1
    const dir = curScroll > prevScroll ? "down" : "up"

    if (Math.abs(prevScroll - curScroll) <= 5) return

    if (stickyHeader) {
      if (direction === "down") {
        setSticky(true)
        stickyHeader.classList.add("active")
      } else {
        setSticky(false)
        stickyHeader.classList.remove("active")
      }
    } else {
      setSticky(false)
    }

    if (curScroll > (window.innerHeight * 0.65)) {
      setScrolledLanding(true)
    } else {
      setScrolledLanding(false)
    }

    // when scrolled to bottom of the page toggle headerHide
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 200) {
      setScrolledFooter(true)
      if (document.querySelector(".service-intro__extension")) {
        document.querySelector(".service-intro__extension").classList.add("hide")
      }
    } else {
      setScrolledFooter(false)
      if (document.querySelector(".service-intro__extension")) {
        document.querySelector(".service-intro__extension").classList.remove("hide")
      }
    }

    prevScroll = curScroll

    requestAnimationFrame(() => {
      setScrolled(scr)
      setDirection(dir)
    })
  }

  const scrollDetect = () => {
    window.clearTimeout(isScrolling)
    isScrolling = setTimeout(onScroll, 0)
  }

  React.useEffect(() => {
    window.addEventListener("scroll", scrollDetect, false)
    return () => {
      window.removeEventListener("scroll", scrollDetect, false)
    }
  }, [])

  React.useEffect(() => {

  }, [])

  let props = {
    onClick: () => {
      setOffCanvas(false)
    },
    activeClassName: "active",
  }

  const renderServices = (services = [], props) => {
    const chunkSize = 4
    const chunks = []

    for (let i = 0; i < services.length; i += chunkSize) {
      chunks.push(services.slice(i, i + chunkSize))
    }

    return chunks.map((chunk, chunkIndex) => (
      <div key={chunkIndex} className="off-canvas__items--inside">
        <div className="line line--horizon center-left"></div>
        {chunk.map((el, i) => (
          <Link key={el.permalink || i} to={el.permalink} className="off-canvas__card" {...props}>
            {el.image && <div className='off-canvas__card--image' ref={(el) => (serviceCardsRef.current[i] = el)}><Asset asset={el.image} /></div>}
            {el.title && <h3>{parse(el.title)}</h3>}
            <RightArrowSmall />
          </Link>
        ))}
      </div>
    ))
  }

  useEffect(() => {
    if (dropdownMenu === 1) {
      gsap.to(projectCardsRef.current, {
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        duration: 0.5,
        stagger: 0.1,
        ease: "power3.inOut",
      })
    } else {
      gsap.to(projectCardsRef.current, {
        clipPath: "polygon(0% 0%, 100% 0%, 100% 0.01%, 0% 0.01%)",
      })
    }

    if (dropdownMenu === 2) {
      gsap.to(serviceCardsRef.current, {
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        duration: 0.5,
        stagger: 0.1,
        ease: "power3.inOut",
      })
    } else {
      gsap.to(serviceCardsRef.current, {
        clipPath: "polygon(0% 0%, 100% 0%, 100% 0.01%, 0% 0.01%)",
      })
    }

    if (dropdownMenu === 3) {
      gsap.to(cultureCardsRef.current, {
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        duration: 0.5,
        stagger: 0.1,
        ease: "power3.inOut",
      })
    } else {
      gsap.to(cultureCardsRef.current, {
        clipPath: "polygon(0% 0%, 100% 0%, 100% 0.01%, 0% 0.01%)",
      })
    }
  }, [dropdownMenu])

  let headerClass = "header"
  if (scrolled) headerClass += " header--scrolled"
  if (scrolledLanding) headerClass += " header--scrolled-landing"
  if (offCanvas) headerClass += " header--off-canvas"
  if (sticky) headerClass += " header--sticky"
  if (scrolledFooter) headerClass += " header--footer"
  if (direction === "down" && scrolled) headerClass += " header--up"

  return (
    <>
      <header className={headerClass}>
        <div className="header__inner">
          { !offCanvas && <Link className="header__logo" to="/"><Logo /></Link> }
          { offCanvas && <button onClick={openOffCanvas} className="header__logo" ><Logo /></button> }
          <div className="header__nav">
            <ul>
              <li><Link to="/contact" {...props} className="btn btn--white">Contact</Link></li>
              <li>
                <button onClick={openOffCanvas} className="btn btn--border-white">{offCanvas ? "Close" : "Menu"}</button>
              </li>
            </ul>
          </div>
        </div>
      </header>

      <div className={`off-canvas ${hoveredIndex ? 'off-canvas__menu--open' : ''} ${offCanvas ? "active" : ""}`}>
        <div className="off-canvas__inner">
          <HeaderVisual menuHover={subMenu} hasOffCanvas={offCanvas} />
          <div className="off-canvas__nav">
            <div className="line line--horizon line--mobile center-right"></div>
            <ul>
              <li className={hoveredIndex !== null && hoveredIndex !== 1 ? "inactive" : ""}
                  onMouseEnter={() => dropdownToggle(1)} onMouseLeave={() => dropdownToggle(false)}>
                <Link to='/projects/' {...props}>Projects</Link>
                <div
                  className={`off-canvas__sub off-canvas__sub--projects ${dropdownMenu === 1 ? "active" : ""}`}>
                  <div className="line line--horizon center-left"></div>
                  <div className="line line--horizon center-left"></div>
                  <div className="line line--horizon center-left"></div>
                  <div className="line line--horizon center-left"></div>
                  <button onClick={() => dropdownToggle(false)} className="off-canvas__back"><ArrowLeft />Projects</button>

                  <div className="off-canvas__items">
                    <div className="line line--vertical center-bottom"></div>
                    <div className="line line--vertical center-bottom"></div>
                    <div className="line line--vertical center-bottom"></div>
                    <div className="line line--vertical center-bottom"></div>
                    {optionsPage?.projectsMenuImage && <div ref={(el) => (projectCardsRef.current[0] = el)} className='off-canvas__card--image'>
                      <Link {...props} to={optionsPage?.projectMenuLink?.slug?.includes('projects') ? `/${optionsPage?.projectMenuLink?.slug}/` : `/project/${optionsPage?.projectMenuLink?.slug}/` }><Asset  asset={optionsPage.projectsMenuImage} /></Link></div>}
                  </div>
                </div>
              </li>
              <li className={hoveredIndex !== null && hoveredIndex !== 2 ? "inactive" : ""}
                  onMouseEnter={() => dropdownToggle(2)} onMouseLeave={() => dropdownToggle(false)}>
                <button>Services</button>
                <div
                  className={`off-canvas__sub off-canvas__sub--services ${dropdownMenu === 2 ? "active" : ""}`}>
                  <div className="line line--horizon center-left"></div>
                  <div className="line line--horizon center-left"></div>
                  <button onClick={() => dropdownToggle(false)} className='off-canvas__back'><ArrowLeft/>Services</button>

                  <div className="off-canvas__items">
                    <div className="line line--vertical center-bottom"></div>
                    <div className="line line--vertical center-bottom"></div>
                    <div className="line line--vertical center-bottom"></div>
                    <div className="line line--vertical center-bottom"></div>
                    <div className="line line--vertical center-bottom"></div>
                    {renderServices(services, props)}
                  </div>
                </div>
              </li>
              <li className={hoveredIndex !== null && hoveredIndex !== 3 ? "inactive" : ""}
                  onMouseEnter={() => dropdownToggle(3)} onMouseLeave={() => dropdownToggle(false)}>
                <button>Culture</button>
                <div className={`off-canvas__sub off-canvas__sub--culture ${dropdownMenu === 3 ? "active" : ""}`}>
                  <div className="line line--horizon center-left"></div>
                  <div className="line line--horizon center-left"></div>
                  <button onClick={() => dropdownToggle(false)} className="off-canvas__back"><ArrowLeft />Culture</button>

                  {
                    optionsPage?.cultureMenu?.length > 0 &&
                    <div className="off-canvas__items">
                      <div className="line line--vertical center-bottom"></div>
                      <div className="line line--vertical center-bottom"></div>
                      <div className="line line--vertical center-bottom"></div>
                      <div className="line line--vertical center-bottom"></div>
                      <div className="line line--vertical center-bottom"></div>

                      <div className="off-canvas__items--inside">
                        <div className="line line--horizon center-left"></div>

                        {
                          optionsPage?.cultureMenu?.map((el, i) => (
                            <Link
                              {...props}
                              key={i}
                              className="off-canvas__card"
                              to={el.link[0].link[0].externalLink ? el.link[0].link[0].externalLink : el.link[0].link[0].internalLink.permalink}>
                              {el.image && <div ref={(el) => (cultureCardsRef.current[i] = el)} className='off-canvas__card--image'><Asset asset={el.image} /></div>}
                              <h3>{el.link[0].title}</h3>
                              <RightArrowSmall />
                            </Link>
                          ))
                        }
                      </div>
                    </div>
                  }
                </div>
              </li>
              <li className={hoveredIndex !== null && hoveredIndex !== 4 ? "inactive" : ""}>
                <Link to="/news/" {...props}>News</Link>
              </li>
              <li className={hoveredIndex !== null && hoveredIndex !== 5 ? "inactive" : ""}>
                <Link to="/contact/" {...props}>Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header